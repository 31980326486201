import React, { useState } from "react"
import {
  Wrapper,
  TitleWrapper,
  SubtitleWrapper,
  InputWrapper,
  ButtonWrapper,
} from "./MotDePasseOublie.module.css"
import { PaddingTop50 } from "./../../../css/Padding.module.css"
import { classNames } from "../../../helpers/classNames"
import Button from "./../../../components/Button"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import firebase from "gatsby-plugin-firebase"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const MotDePasseOublie = () => {
  const [successSnackbarIsOpened, setSuccessSnackbarIsOpened] = useState(false)
  const [errorSnackbarIsOpened, setErrorSnackbarIsOpened] = useState(false)

  const [email, setEmail] = useState("")

  const handleSuccessClose = () => {
    setSuccessSnackbarIsOpened(false)
  }

  const handleErrorClose = () => {
    setErrorSnackbarIsOpened(false)
  }

  const handleValidateButton = () => {
    if (email.length > 0) {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          setSuccessSnackbarIsOpened(true)
        })
        .catch(error => {
          //console.log("error: ", error)
          setErrorSnackbarIsOpened(true)
        })
      // firebase forget password
    } else {
      setErrorSnackbarIsOpened(true)
    }
  }

  return (
    <div className={Wrapper}>
      <h4 className={TitleWrapper}>Mot de passe oublié</h4>
      <p className={SubtitleWrapper}>
        Entre ton adresse mail et tu recevras un lien pour réinitialiser ton mot
        de passe.
      </p>
      <div className={classNames([InputWrapper, PaddingTop50])}>
        <input
          type="name"
          placeholder="ex: name@example.com"
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      <div className={ButtonWrapper}>
        <Button alt="" onClick={() => handleValidateButton()}>
          Valider
        </Button>
      </div>
      <Snackbar
        open={successSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleSuccessClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Un message vient de t'être envoyé sur ta boîte mail pour réinitialiser
          ton mot de passe.
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Oops... Une erreur est survenue. Vérifie les informations que tu as
          rentré.
        </Alert>
      </Snackbar>
    </div>
  )
}

export default MotDePasseOublie
