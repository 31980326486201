import React, { useEffect, useState } from "react";
import Layout from "./../../components/Layout";
import MotDePasseOublie from "./../../nav/SeConnecter/MotDePasseOublie";
import { Helmet } from "react-helmet";
import { getUser } from "./../../auth";
import { TailSpin } from "react-loader-spinner";
const isBrowser = typeof window !== "undefined";

const WhichProfile = ({ user }) => {
  if (user.subscription.type === "admin") {
    if (isBrowser) {
      window.location.href = "/admin/profil";
    }
    return (
      <div style={{ textAlign: "center" }}>
        <TailSpin color="#FF8000" height={200} width={200} />
      </div>
    );
  } else {
    if (isBrowser) {
      window.location.href = "/utilisateurs/profil";
    }
    return (
      <div style={{ textAlign: "center" }}>
        <TailSpin color="#FF8000" height={200} width={200} />
      </div>
    );
  }
};

const MotDePasseOubliePage = () => {
  const [user, setUser] = useState(undefined);
  useEffect(() => {
    getUser(setUser);
  }, []);

  return (
    <Layout user={user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Mot de passe oublié</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-30 ans. Sur cette page,
          tu trouveras un formulaire à utiliser dans le cas tu as oublié ton mot de passe."
        />
        <meta
          name="keywords"
          content="réductions,jeunes,connexion,mot de passe"
        />
        <link
          rel="canonical"
          href="https://wynit.com/se-connecter/mot-de-passe-oublie"
        />
      </Helmet>
      {user === undefined ? (
        <div style={{ textAlign: "center" }}>
          <TailSpin color="#FF8000" height={200} width={200} />
        </div>
      ) : user ? (
        <WhichProfile user={user} />
      ) : (
        <MotDePasseOublie />
      )}
    </Layout>
  );
};

export default MotDePasseOubliePage;
